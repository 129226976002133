import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            component: () => import('../components/Login.vue')
        },
        {
            path: '/',
            redirect: '/dashboard',
            meta: {requiresAuth: true}
        },
        {
            path: '/dashboard',
            component: () => import('../components/Dashboard.vue'),
            meta: {requiresAuth: true},
            children: [
                {
                    path: 'snippets',
                    component: () => import('../components/Snippets.vue')
                },
                {
                    path: 'snippet/:id',
                    component: () => import('../components/Snippet.vue')
                },
                {
                    path: 'tags',
                    component: () => import('../components/Tags.vue')
                },
                {
                    path: 'images',
                    component: () => import('../components/Images.vue')
                },
                {
                    path: 'translations',
                    component: () => import('../components/Translations.vue')
                },
                {
                    path: 'users',
                    component: () => import('../components/Users.vue')
                },
                {
                    path: 'log-entries',
                    component: () => import('../components/LogEntries.vue')
                }
            ]
        },
        {
            path: '/snippet/:id',
            component: () => import('../components/Snippet.vue'),
            meta: {requiresAuth: true}
        },
        { path: '*', redirect: '/' }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        /*
          TODO: check not only for existing jwt token but also check if it is still valid
         */
        if (!localStorage.getItem('jwt')) {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
        } else {
            next()
        }
    } else {
        // if user is logged in an tries to access login page we should redirect to dashboard
        next()
    }
});

export default router
