import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router'
import vuetify from './plugins/vuetify';
import truncateFilter from './plugins/truncate-filter';

new Vue({
  router,
  vuetify,
  truncateFilter,
  render: h => h(App)
}).$mount('#app');
